<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.year_id" v-validate="'required'" name="year_id" id="year_id" class="form-control form-control-sm" >
                <option :value="''">Select a year</option>
                <option v-for="(year, index) in years" :key="index"  :value="year.id">{{ year.year_number }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <select v-model="search_data.month_id" v-validate="'required'" name="month_id" id="month_id" class="form-control form-control-sm" >
                <option :value="''">Select a month</option>
                <option v-for="(month, index) in months" :key="index" :value="month.id">{{ month.month_name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <a-spin :spinning="spinning" :delay="delayTime">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-sm table-bordered text-center">
              <thead>
              <tr>
                <th>SL</th>
                <th>In Charge</th>
                <th>No of Locations</th>
                <th>Status</th>
                <th>Overall Status</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(admin_user, index) in admin_users_weekly_ratings" :key="index">
                <td>{{ index + 1 }}</td>
                <td style="background-color: #ce65651c; cursor: pointer"><a href="javascript:void(0)" @click.prevent="adminHubDetails(admin_user.user_id, index)" title="Click here">{{ admin_user.name }} <span style="float: right">Click here</span></a></td>
                <td>{{  admin_user.total_hubs  }}</td>
                <td>{{  admin_user.monthly_rating_total_avg  }} %</td>
                <td v-if="index < 1" :rowspan="admin_users_weekly_ratings.length">{{ monthly_total_hubs_avg_rating }} % </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'update',
  components: { },
  data() {
    return {
      years: [],
      months: [],
      admin_users_weekly_ratings: [],
      btnLoading: false,
      loading: false,
      spinning: false,
      delayTime: 500,
      monthly_total_hubs_avg_rating: 0,
      search_data: {
        year_id: '',
        month_id: parseInt(new Date().getMonth()) + 1,
      },
    }
  },
  mounted() {
    this.getCodes()
  },
  methods: {
    getCodes() {
      apiClient.get('api/admin-scenario/getCodes')
        .then(response => {
          this.years = response.data.years
          this.months = response.data.months
          const year = this.years.find((year) => year.year_number === new Date().getFullYear())
          this.search_data.year_id = year.id
          setTimeout(() => {
            this.search()
          }, 2000)
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.spinning = true
      this.loading = true
      this.btnLoading = true
      apiClient.post('api/admin-scenario/admin/overall/issue/search', this.search_data)
        .then(response => {
          this.spinning = false
          this.loading = false
          this.btnLoading = false
          this.admin_users_weekly_ratings = response.data.admin_users_weekly_ratings
          this.monthly_total_hubs_avg_rating = response.data.monthly_total_hubs_avg_rating
        })
        .catch(error => {
          console.log(error)
        })
    },
    adminHubDetails(userId, index) {
      this.$router.push({ name: 'adminOverallHubScenarioDashboard', params: { user_id: userId } })
    },
  },
}
</script>

<style scoped>

</style>
